import { Tooltip } from '@mantine/core'
import Button from 'src/components/Buttons/Button/Button'

interface ApplicationCardFooterProps {
  handleSave?: any
  handleCancel?: any
  isSaveDisabled?: boolean
}

const ApplicationCardFooter = ({
  isSaveDisabled = false,
  handleSave,
  handleCancel,
}: ApplicationCardFooterProps) => {
  return (
    <div className="flex flex-row gap-4">
      <Button
        text="Cancel"
        variant="outline"
        className="ml-auto"
        onClick={handleCancel}
      />
      <Tooltip
        disabled={!isSaveDisabled}
        label={'Please fill out the required fields'}
      >
        <Button text="Save" onClick={handleSave} disabled={isSaveDisabled} />
      </Tooltip>
    </div>
  )
}

export default ApplicationCardFooter
