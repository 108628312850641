import { Loader, Select, SelectProps } from '@mantine/core'

interface SelectInputProps extends SelectProps {
  editing: boolean
  value: string
  data: any[]
  handleChange?: any
}

const SelectInput = ({
  editing,
  value,
  data,
  handleChange,
  ...props
}: SelectInputProps) => {
  return (
    <Select
      disabled={!editing}
      classNames={{
        label: 'text-sm font-medium text-doubleNickel-gray-700',
        input:
          '[&[data-disabled]]:bg-doubleNickel-white [&[data-disabled]]:opacity-100 [&[data-disabled]]:text-doubleNickel-gray-900',
      }}
      variant={editing ? 'default' : 'unstyled'}
      value={value ?? null}
      data={data}
      onChange={(value) => handleChange(value)}
      {...props}
    />
  )
}

export default SelectInput
