import { ActionIcon, Badge } from '@mantine/core'
import { useState } from 'react'

import Button from 'src/components/Buttons/Button/Button'
import IconAdd from 'src/icons/IconAdd'
import IconChevronDown from 'src/icons/IconChevronDown'
import IconEdit from 'src/icons/IconEdit'

interface ApplicationCardHeaderProps {
  title: string
  canAdd?: boolean
  editing?: boolean
  setEditing?: any
  handleAdd?: any
  readonly?: boolean
  isEmpty?: boolean
  opened?: boolean
  setOpened?: any
}

const ApplicationCardHeader = ({
  title,
  canAdd = true,
  isEmpty = false,
  readonly = false,
  opened = false,
  setOpened,
  editing,
  setEditing,
  handleAdd,
}: ApplicationCardHeaderProps) => {
  return (
    <div
      className="text-md flex w-full flex-row items-center gap-4 px-2 font-semibold text-doubleNickel-black"
      onClick={() => setOpened((prev) => !prev)}
    >
      <ActionIcon variant="subtle">
        <IconChevronDown
          className={`h-4 w-4 ${
            !opened && '-rotate-90'
          } fill-none stroke-doubleNickel-gray-500 transition-all`}
        />
      </ActionIcon>
      {title}
      {isEmpty && (
        <Badge variant="light" className="rounded" color="blue">
          No data
        </Badge>
      )}
      {!readonly && (
        <>
          {!editing ? (
            isEmpty && canAdd ? (
              <ActionIcon
                className="ml-auto h-8 w-8 border border-doubleNickel-gray-200 bg-doubleNickel-white shadow-sm hover:bg-doubleNickel-gray-200"
                onClick={(e) => {
                  e.stopPropagation()
                  if (!opened) setOpened(true)
                  setEditing(true)
                  handleAdd()
                }}
              >
                <IconAdd className="h-4 w-4 fill-none stroke-doubleNickel-gray-500" />
              </ActionIcon>
            ) : (
              <ActionIcon
                className="ml-auto h-8 w-8 border border-doubleNickel-gray-200 bg-doubleNickel-white shadow-sm hover:bg-doubleNickel-gray-200"
                onClick={(e) => {
                  e.stopPropagation()
                  if (!opened) setOpened(true)
                  setEditing(true)
                }}
              >
                <IconEdit className="h-4 w-4 fill-none stroke-doubleNickel-gray-500" />
              </ActionIcon>
            )
          ) : (
            canAdd && (
              <ActionIcon
                className="ml-auto h-8 w-8 border border-doubleNickel-gray-200 bg-doubleNickel-white shadow-sm hover:bg-doubleNickel-gray-200"
                onClick={(e) => {
                  e.stopPropagation()
                  if (!opened) setOpened(true)
                  handleAdd()
                }}
              >
                <IconAdd className="h-4 w-4 fill-none stroke-doubleNickel-gray-500" />
              </ActionIcon>
            )
          )}
        </>
      )}
    </div>
  )
}

export default ApplicationCardHeader
