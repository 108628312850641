const StyledReadOnlyInput = ({
  label = null,
  value,
  required = false,
  ...props
}) => {
  return (
    <div className="flex flex-col gap-2 pt-1" {...props}>
      {label && (
        <div className="flex flex-row gap-1 text-sm font-medium text-doubleNickel-gray-700">
          {label}
          {required && <span className="text-red-500">*</span>}
        </div>
      )}
      <div className="text-sm text-doubleNickel-gray-900">
        {value === '' || value == null ? '-' : value}
      </div>
    </div>
  )
}

export default StyledReadOnlyInput
