import { ActionIcon, Badge, Collapse, Tooltip } from '@mantine/core'
import { useState } from 'react'

import Button from 'src/components/Buttons/Button/Button'
import IconAdd from 'src/icons/IconAdd'
import IconChevronDown from 'src/icons/IconChevronDown'
import IconEdit from 'src/icons/IconEdit'
import ApplicationCardHeader from './ApplicationCardHeader'
import ApplicationCardFooter from './ApplicationCardFooter'
import StyledAlert from 'src/components/Feedback/StyledAlert/StyledAlert'

interface ApplicationCardProps {
  title: string
  canAdd?: boolean
  editing?: boolean
  setEditing?: any
  handleSave?: any
  handleCancel?: any
  handleAdd?: any
  children: any
  isSaveDisabled?: boolean
  readonly?: boolean
  isEmpty?: boolean
  showAlert?: boolean
}

const ApplicationCard = ({
  title,
  canAdd = true,
  isEmpty = false,
  isSaveDisabled = false,
  readonly = false,
  editing = false,
  showAlert = isEmpty,
  setEditing,
  handleSave,
  handleCancel,
  handleAdd,
  children,
}: ApplicationCardProps) => {
  const [opened, setOpened] = useState(!isEmpty)

  return (
    <div
      className={`flex w-full flex-col gap-4 rounded-lg border border-doubleNickel-gray-200 p-3`}
    >
      <ApplicationCardHeader
        title={title}
        opened={opened}
        setOpened={setOpened}
        canAdd={canAdd}
        isEmpty={isEmpty}
        readonly={readonly}
        editing={editing}
        setEditing={setEditing}
        handleAdd={handleAdd}
      />

      <Collapse in={opened} transitionDuration={400}>
        <div className="flex flex-col gap-4">
          {showAlert ? (
            <StyledAlert
              title={`No ${title.toLowerCase()} found`}
              description="Add a new item to get started"
            />
          ) : (
            <div className="px-4">{children}</div>
          )}
          {editing && (
            <ApplicationCardFooter
              handleSave={handleSave}
              handleCancel={handleCancel}
              isSaveDisabled={isSaveDisabled}
            />
          )}
        </div>
      </Collapse>
    </div>
  )
}

export default ApplicationCard
